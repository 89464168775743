.grid {
	display: flex;
	flex-wrap: wrap;
	transition: transform 0.2s ease-in-out;

	.results_grid {
		padding: 0.5vw;
		display: flex;
		flex-wrap: wrap;

		.search_card {
			width: 11.94vw;
			height: 17.03vw;
		}
	}

	.grid_item {
		padding: 0.5vw;
		margin: 0.5vw 0;

		& > div {
			margin-right: 0;
		}
	}
}
