.mainItem {
	width: 100%;
	font-size: 1.458vw;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	padding: 0.8vw 0vw 1vw 3vw;
	margin-bottom: 1vw;
	color: var(--grey11);
}

.subOptions {
	max-height: 85vh;
	width: 100%;
}
