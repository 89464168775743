.container {
	height: 100vh;
	display: flex;

	.content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 31.25rem;
		padding-bottom: 0;
		margin-top: -5vw;

		.dstv_logo {
			text-align: right;
			width: 5.73vw;
			height:auto;
			position: fixed;
			top: 1.13vw;
			right: 3.13vw;
		}

		h3 {
			font-weight: 700;
			color: #f1f1f1;
			margin: 4rem;
			font-size: var(--font-xl-2);
		}
	}
}
