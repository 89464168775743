.component {
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	width: 100vw;
}

.dstv_logo {
	text-align: right;
	width: 5.73vw;
	height:auto;
	position: fixed;
	top: 3.13vw;
	right: 3.13vw;
}

.title {
	margin-top: 5vw;
	font-size: 2.6vw;
	font-weight: bold;
	text-align: center;
	z-index: 2;
}

.action__container {
	text-align: center;
	display: flex;
	flex-direction: column;
}
