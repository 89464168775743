.error_full {
	position: absolute;
	right: 0;
	background-color: rgba(0, 0, 0, 0.85);
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	justify-content: center;
	text-align: center;
	z-index: 20;
	.content_wrapper {
		margin-bottom: 5.2vw;
		.main_heading {
			font-size: 2.5vw;
			margin-bottom: 2.5vw;
			font-weight: bold;
		}

		.sub_heading {
			margin-bottom: 0.52vw;
			font-size: 1.88vw;
			line-height: 2.76vw;
			letter-spacing: 0;
			font-weight: bold;
		}

		.body {
			font-size: 1.88vw;
			line-height: 2.76vw;
			max-width: 52vw;
		}
		.large {
			font-size: 1.88vw;
			line-height: 2.76vw;
			max-width: 72vw;
		}
		p + p {
			margin-top: 2vw;
		}
	}

	.buttons_wrapper {
		display: flex;
		margin: -1.04vw;

		> * {
			margin: 1.04vw;
		}
	}
}
