.keyboard_key {
	align-items: center;
	color: #919191;
	display: flex;
	float: left;
	font-size: 2.08vw;
	height: 3.75vw;
	justify-content: center;
	text-align: center;
	width: 3.125vw;
	border-radius: 0.26vw;
	margin-left: 0.1vw;
	margin-bottom: 0.521vw;

	&.active {
		background-color: var(--dn-aqua);
		color: var(--black);
	}
}
