
.input_keyboard {
  width: 27.08vw;
  margin-bottom: 2vw;

  .errorMessage {
    color: #943131;
    font-size: 0.9rem;
    line-height: 2.5rem;
    text-align: left;
  }
  .input_wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .input {
      background-color: transparent;
      border: 1px solid #919191;
      color: #919191;
      direction: rtl;
      font-size: 1.56vw;
      height: 4.17vw;
      line-height: 4.17vw;
      outline: none;
      overflow: hidden;
      padding: 0 1.06vw;
      text-align: left;
      width: 100%;

      &.error{
        border-color: #943131;
      }
      &.active{
        border-color: #f1f1f1;
      }

      .password{
        -webkit-text-security: disc;
      }
    }

    .password_toggle {
      display: flex;
      align-items: center;
      flex: 0 0 4.17vw;
      justify-content: center;
      border: 1px solid #919191;
      background-color: transparent;
      height: 4.17vw;
      margin-left: 0.52vw;

      &.active {
        background-color: #f1f1f1;
        border-color: #f1f1f1;

        > div {
          background-position: right center;
        }
      }

      > div {
        background-position: left center;
        background-repeat: repeat-x;
        background-size: 200%;
        display: block;
        height: 2.1875vw;
        position: relative;
        width: 2.1875vw;
      }
    }
  }
}
