.card_container {
	display: inline-block;
	position: relative;
	width: 20.625vw;
	height: 15.417vw;
	display: inline-block;
	margin: 0vw 0.73vw 0vw 0.73vw;

	&.active {
		border-color: var(--white-tv);
		transform: scale3d(1.08, 1.08, 1.18);
		filter: brightness(1);

		.card_image {
			border: 0.2083vw solid var(--white-tv);
		}
		.card_overlay {
			background: none;

			.card_playButton {
				width: 4.167vw;
			}
		}
	}
	.card_image_container {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.contained_card_image_container {
		position: absolute;
		width: 100%;
		height: 11.61vw;
		z-index: 1;
	}
	.card_image {
		position: absolute;
		width: 100%;
		height: 11.61vw;
		object-fit: cover;
		border-radius: 1.04vw;
		z-index: -2;
	}

	.card_overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 11.61vw;
		border-radius: 1.04vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		opacity: 1;
		transition: opacity 0.3s ease;
		z-index: -1;
		margin-bottom: 0.26vw;

		.card_playButton {
			opacity: 1;
			color: var(--grey23);
			text-align: center;
			z-index: 1;
			margin-bottom: 1.04vw;
			width: 3.125vw;
		}

		.progress_wrapper {
			z-index: 1;
			width: 18.85vw;
			height: 0.208vw;
			flex-shrink: 0;
			position: absolute;
			bottom: 0.72vw;
		}

		.time_left {
			font-size: 1.04vw;
			color: var(--grey23);
			margin-bottom: 0.309vw;
			margin-left: 0.89vw;
			align-self: flex-start;
			position: absolute;
			bottom: 1.04vw;
		}
	}
}
.hidden {
	visibility: hidden;
}
