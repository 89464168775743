.channel_event_wrapper {
	padding-right: 0.42vw;
	position: absolute;

	.channel_event {
		background-color: var(--grey16);
		border-radius: 1vw;
		display: flex;
		height: 6.09vw;
		justify-content: space-between;
		overflow: hidden;
		padding: 0.52vw;
		font-family: var(--font-family);

		h3,
		p {
			color: var(--grey14);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		h3 {
			font-size: 1.56vw;
			font-weight: 700;
		}

		p {
			font-size: 1.25vw;
			font-weight: 400;
		}

		img {
			height: auto;
			width: 3.02vw;
		}

		&.focus {
			background-color: #666666;
		}

		.channel_event_left {
			display: flex;
			flex: 1 1 auto;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;
			padding: 0.52vw;
		}
		.active {
			color: var(--white-tv);
		}

		.channel_event_right {
			align-items: center;
			display: flex;
			flex: 1 1 auto;
			justify-content: flex-end;
			padding: 0.52vw;

			&.center {
				margin: 0;
				width: 100%;
				justify-content: center;
			}
		}
	}
}
