.confirmation_box {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 68vh;
	flex-direction: column;

	.content_wrapper{
		margin-bottom: 5.2vw;

		.main_heading {
			font-size: 2.5vw;
			margin-bottom: 2.5vw;
			font-weight: bold;
		}
	
		.sub_heading {
			margin-bottom: 0.52vw;
			font-size: 1.88vw;
			line-height: 2.76vw;
			letter-spacing: 0;
			font-weight: bold;
		}
	
		.body {
			font-size: 1.56vw;
			line-height: 2.76vw;
		}
	}

	.buttons_wrapper {
		display: flex;

		button:first-child {
			margin-right: 1.04vw;
		}
	}
}
