.progress_bar {
	background-color: rgba(241, 241, 241, 0.3);
	border-radius: 100vw;
	height: 100%;
	width: 100%;

	&.active {
		.progress {
			background-color: var(--dn-core-blue);
		}
	}

	.progress {
		background-color: #a9a9a9;
		border-radius: 100vw;
		height: 100%;
	}
}
