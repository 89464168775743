:root {
	--transition-duration: 0.3s;
	--transition-timing: ease-in-out;
	--z-index-bottom: 99;
	--z-index-mid: 999;
	--z-index-top: 9999;
	--button-border-radius: 0.4vw;
	--button-font-size: 0.9vw;
	--button-font-LH: 0.9vw;
	--txt-font-size: 0.9vw;
	--button-height: 3.7vh;
	--button-padding-horizontal: 0.83vw 1.5vw;
	--button-min-width: 6.3vw;
	--button-border-width: 0;
	--frame-border-width: 20px;
	--font-weight: 500;
	--black-color: rgba(0, 0, 0, 0.7);
	--full-black: rgb(0, 0, 0);
	--shadow: -5px -5px 5px -2px rgba(0, 0, 0, 0.1), 5px -5px 5px -2px rgba(0, 0, 0, 0.1),
		-5px 5px 5px -2px rgba(0, 0, 0, 0.1), 5px 5px 5px -2px rgba(0, 0, 0, 0.1);
	--font-family: 'Poppins', sans-serif;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.hidden {
	opacity: 0;
}

.adContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-mid);
	background-color: var(--full-black);
}

.adPlayer,
.adPausePlayer {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: var(--z-index-mid);
}

.adPlayer img,
.adPausePlayer img {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: auto;
}

.pulseBtn {
	position: absolute;
	align-items: center;
	background: transparent;
	border-radius: var(--button-border-radius);
	color: var(--white-tv);
	display: flex;
	font-family: var(--font-family);
	font-size: var(--font-lg-1);
	font-weight: 500;
	height: 3.54vw;
	min-width: 3.54vw;
	background: var(--grey12);
	border: 0.1vw solid var(--grey12);
	width: fit-content;
	transition: all 0.3s ease;
}

.pulseBtn span {
	padding: var(--button-padding-horizontal);
	width: 100%;
}

.pulseBtn.active {
	color: var(--black);
	border: none;
	background: var(--dn-core-blue);
	border: var(--button-border-width) solid var(--dn-core-blue);
}

.pulseSkipButton {
	animation: fadeIn var(--transition-duration) var(--transition-timing);
	right: 2vw;
	bottom: 2vw;
	z-index: var(--z-index-top);

	box-shadow: var(--shadow);
}

.pulsePauseButton {
	animation: fadeIn var(--transition-duration) var(--transition-timing);
	right: 2vw;
	top: 2vw;
	z-index: var(--z-index-top);

	box-shadow: var(--shadow);
}

.pauseAdContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: var(--black-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: var(--z-index-mid);
}

.pauseAdContainer img {
	position: relative;
	max-width: 100%;
	max-height: 100%;
	height: auto;
	width: auto;
}
