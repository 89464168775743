.player_thumbnail_wrapper {
	position: relative;
	width: 100%;

	.player_thumbnail {
		border: 0.1vw solid #fff;
		height: 9.375vw;
		position: absolute;
		transform: translate(-50%, -120%);
		width: 16.666vw;
	}

	.player_timestamp {
		height: 4.75vw;
		position: absolute;
		transform: translate(-50%, -120%);
	}

	span {
		background-color: var(--white-tv);
		bottom: 0;
		border-radius: 1.3vw;
		color: #000;
		font-weight: bold;
		font-size: 1.56vw;
		height: 2.6vw;
		left: 50%;
		line-height: 2.6vw;
		padding: 0 1.35vw;
		position: absolute;
		transform: translate(-50%, 50%);
	}
}
