.profile__confirm {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: absolute;
	right: 0;
	width: 100vw;

	.title {
		color: #f1f1f1;
		position: relative;
		text-align: center;
		height: 3.13vw;
		z-index: 2;
		font-size: 2.6vw;
		margin: 8vw 0vw 3vw 0;
	}

	.profile__img {
		height: 9.9vw;
		width: 9.9vw;
		-webkit-filter: brightness(50%);
		filter: brightness(50%);

		&__focus {
			background-color: #f1f1f1;
			border-radius: 1vw;
			border: 0.26vw solid #f1f1f1;
			filter: brightness(100%);
			transform: scale3d(1.15, 1.15, 1);
			height: 9.9vw;
			width: 9.9vw;

			&_edit_on {
				background-color: #f1f1f1;
				border-radius: 1vw;
				border: 0.26vw solid #f1f1f1;
				filter: brightness(100%);
				height: 9.9vw;
				width: 9.9vw;
			}
		}
	}

	.avatar {
		background-color: transparent;
		position: relative;
		object-fit: contain;
		opacity: 0.8;
		padding: 0;
		z-index: 2;
		display: block;
		filter: brightness(100%);
		margin: 3.65vw auto 0vw auto;
		width: 9.9vw;
		height: 9.9vw;

		&__delete {
			margin: 3.65vw auto 0vw auto;
		}

		&__focus {
			background: transparent;
			opacity: 1;
			transform: scale(1.316);
			transform-origin: top;
		}
	}

	.name {
		background-color: transparent;
		z-index: 2;
		position: relative;
		color: #808080;
		font-family: var(--font-family);
		text-align: center;
		font-size: 2.29vw;
		margin: 3.65vw;
		height: 2.6vw;
		width: 20.52vw;

		&__delete {
			color: #f1f1f1;
			margin: 1.46vw auto 0vw auto;
		}

		&__focus {
			color: #f1f1f1;
		}
	}

	.action__container {
		text-align: center;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.description {
		color: #f1f1f1;
		font-size: 1.67vw;
		line-height: 1.5;
		position: relative;
		text-align: center;
		white-space: pre-wrap;
		width: 41.25vw;
		z-index: 2;
		margin: 3.65vw auto 0vw auto;
	}
}

.dstv_logo {
	text-align: right;
	width: 5.73vw;
	position: fixed;
	top: 3.13vw;
	right: 3.13vw;
}
