.FloatingGuide_floatingGuide {
	position: absolute;
	left: 71.45vw;
	background: rgba(142, 142, 142, 0.5);
	box-shadow: 0px 0.208vw 0.208vw 0vw var(--black40);
	padding: 0.521vw 0.781vw;
	border-radius: 1.042vw;
	font-size: 0.833vw;
	width: 18.958vw;
	height: 3.958vw;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 3.8vw;
}

.FloatingGuide_floatingContainer {
	height: 2.708vw;
	gap: 0.417vw;
	opacity: 0px;
	display: flex;
	align-items: center;
	font-family: var(--font-family);
	font-size: 1.146vw;
}

.hold_prompt {
	color: var(--dn-core-blue);
	border-radius: 2.708vw;
	width: 2.708vw;
	height: 2.708vw;
	font-family: var(--font-family);
	font-size: 1.25vw;
	border: 0.052vw solid var(--black41);
	display: flex;
	align-items: center;
	justify-content: center;
	background: linear-gradient(180deg, rgba(14, 14, 14, 0) -1vw, #0c0c0c 3vw);
	margin: 0.2vw;
}
