.TvSearchBar__search_bar {
	border-bottom: 0.05vw solid var(--grey22);
	display: block;
	margin-left: 3vw;
	width: 88vw;
	padding: 3.5vw 0 0.5vw;
	text-align: left !important;
	text-transform: lowercase;
	overflow: hidden;

	.TvSearch_icon {
		height: 1.46vw;
		margin-left: -1.77vw;
		margin-top: 0.78vw;
		position: absolute;
		width: 1.46vw;
	}

	.search_input {
		background: none;
		border: none;
		color: #fff;
		display: inline-block;
		font: 700 1.86vw/2.81vw var(--font-family);
		position: relative;
		text-align: left;
		top: 0.16vw;
		width: 75.78vw;
	}

	.search_placeholder {
		color: #9b9b9b !important;
		text-transform: initial;
		font-weight: normal;

		input[type='text'] {
			border: none;
			background-color: black;
			outline: 0;
			color: var(--white);
			font-size: var(--font-xl);
			font-weight: 600;
			width: 88vw;
			height: 3vw;
		}

		input[type='text']:focus {
			color: white;
			border: none;
			background-color: black;
			outline: 0;
			width: 88vw;
			height: 2vw;
		}
	}
}
