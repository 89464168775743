.onboarding {
	height: 100vh;
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10 vw;
}
.background {
	background: url('../../../assets/images/bg_onboarding.jpg') no-repeat center right;
	background-size: auto 100%;
}

.logo {
	left: 3.13vw;
	position: absolute;
	top: 3.13vw;
	width: 7.55vw;
}
