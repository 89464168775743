.keyboard_wrapper {
	.keyboard_keys {
		height: 25.125vw;
		width: 20.57vw;
	}

	.action_buttons {
		display: flex;
		margin-top: 0.521vw;
	}
}
