.live_controls_wrapper,
.vod_controls_wrapper {
	position: absolute;
	z-index: 25;
	bottom: 0;
	right: 0;
	top: 0;
	width: 100vw;
	height: 100vh;

	.time_widget_wrapper {
		position: absolute;
		right: 4vw;
		top: 3vw;
		z-index: 1;
	}

	&.visible {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.player_controls {
		bottom: 4vw;
		padding: 0vw 10vw;
		position: absolute;
		width: 100%;

		.metadata_wrapper {
			margin-bottom: 1.66vw;
			.channel_details {
				align-items: center;
				display: flex;
				justify-content: left;
				margin-bottom: 1.3vw;

				h5 {
					color: var(--white-tv);
					font-family: var(--font-family);
					font-size: 1.34vw;
					font-weight: 400;
					text-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.5);
				}

				img {
					max-width: 4.38vw;
					max-height: 3.33vw;
					padding-right: 1.41vw;
				}
			}

			h1 {
				font-size: 3.13vw;
				text-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.5);
			}

			h3 {
				font-size: 1.56vw;
				font-weight: 500;
				text-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.5);
			}
		}

		.times_wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 2vw;

			> div {
				flex: 1;

				&:last-child {
					text-align: right;
				}
			}
		}

		.progress_wrapper {
			height: 0.42vw;
			margin-bottom: 1.09vw;
		}

		.controls_wrapper {
			position: relative;
			display: flex;
			justify-content: space-between;

			> div {
				float: left;
				position: relative;
				min-width: auto;
			}

			.prev_wrapper,
			.next_wrapper {
				display: flex;
				width: 13.65vw;
				position: absolute;
			}

			.next_wrapper {
				left: calc(100% + 2vw);
			}

			.prev_wrapper {
				justify-content: flex-end;
				right: calc(100% + 2vw);
			}

			.controls_center {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 8.458vw;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}

			.controls_right_vod {
				display: flex;
			}

			.controls_right_livetv {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.live_indicator {
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0.15vw solid var(--dn-stream-red);
				background: var(--dn-stream-red);
				border-radius: 0.52vw;
				height: 3.8vw;
				min-width: 3.54vw;
				font-size: 1.4vw;
				padding-left: 0.5vw;
				padding-right: 0.5vw;

				.live_dot {
					display: inline-block;
					border-radius: 0.75vw;
					height: 0.75vw;
					width: 0.75vw;
					background-color: var(--white);
				}

				.title {
					margin-left: 0.5vw;
				}
			}
		}

		.buttons_wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-top: 1vw;
		}
	}
}

.skip_controls_wrapper {
	display: flex;
	height: 100vh;
	position: absolute;
	width: 100vw;
}
