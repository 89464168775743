.container {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	position: fixed;
	right: 0px;
	top: 0px;
	width: 100vw;
	z-index: 30;
	background: #1919ba;

	img {
		width: 70vw;
		height: auto;
	}
}
