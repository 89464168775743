div.icon-wrapper {
	width: 3.96vw !important;
	height: 3.96vw !important;
	padding: 0.89vw !important;
	img {
		width: 100%;
		height: auto;
		filter: brightness(0.5);
	}

	&.active {
		background-color: var(--grey10) !important;
		border-color: var(--btn-bg-blue) !important;
		img {
			filter: brightness(1);
		}
	}
	
}