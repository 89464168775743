@import '../../../assets/styles/mixins';

.episodes {
	.episode_list_wrapper {
		position: relative;
		top: 2.222vh;
		z-index: 1;
		width: 100%;
		display: flex;

		.episode_list {
			@include smooth-transition;
			background-color: rgba(0, 0, 0, 0.1);
			height: auto;
			position: relative;
			transition: transform 0.2s ease-in-out;
			width: auto;
			display: flex;

			.episode_wrapper {
				display: flex;
				padding-left: 0vw;
				padding-right: 9.95vw;
				padding-bottom: 5.185vh;
			}
		}
	}
}
