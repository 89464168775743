.sub_option_item {
	position: relative;
	height: 7vw;
	display: flex;
	width: 100%;
	align-items: center;
	padding-left: 2.6vw;

	&.focus {
		background-color: #03ffeb;
		color: black;
	}

	& + .sub_option_item {
		margin-top: 1vw;
	}

	.radio_button_wrapper {
		margin-right: 1.56vw;

		img {
			height: auto;
			width: 1.875vw;
		}
	}

	.sub_option_text_wrapper {
		.title {
			font-size: 1.458vw;
			font-weight: 700;
		}

		.description {
			font-size: 1.198vw;
			font-weight: 400;
		}
	}
}
