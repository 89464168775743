@import '../../../assets/styles/mixins';

.channels_container {
	@include smooth-transition;
	background-color: rgba(0, 0, 0, 0.5);
	margin-left: 4.167vw;
	margin-top: -11vw;
	position: relative;
	transition-duration: 0.5s;
	transition-property: transform;
	width: 100vw;
	overflow: hidden;

	&.active {
		transform: translate3d(0, 10vw, 0);
	}

	.scroll_container {
		display: flex;
	}

	.day_label {
		color: #808080;
		font-size: 1.35vw;
		font-weight: 500;
	}

	.channel_logos {
		position: sticky;
		margin-right: 0.42vw;
		width: 8.44vw;
	}

	.events_wrapper {
		outline-width: 0;
		overflow-y: hidden;
		position: relative;
		z-index: -1;
	}
}
