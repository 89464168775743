.player_container {
	width: 100vw;
	height: 100vh;
	z-index: 20;

	video {
		height: 100%;
		width: 100%;
	}
}
