.player_button {
	align-items: center;
	border-radius: 1vw;
	display: flex;
	font-size: 1.35vw;
	font-weight: bold;
	height: 5.21vw;
	justify-content: center;
	opacity: 0.4;
	position: relative;

	&.round {
		border-radius: 100vw;
	}

	&.extra_margin {
		margin-left: 1vw;
	}

	img {
		width: 2.8vw;

		&.reversed {
			transform: rotateY(180deg);
		}
	}

	.player_button_logo {
		width: 4.38vw;

		img {
			width: 100%;
		}
	}

	&.focus {
		background-color: var(--dn-core-blue);
		color: #000;
		opacity: 1;
		min-width: 5.21vw;
		span {
			width: 0vw;
		}

		> * {
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		> img {
			filter: invert(1);
		}

		&.has_label {
			height: 3.8vw;
			padding: 1.3vw 1.94vw;
			position: relative;
			margin: 0 0 0 1.3vw;

			span {
				width: 0.8vw;
			}

			& > img {
				width: 1.25vw;
			}
		}
	}
}
