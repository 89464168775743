.button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0.15vw solid var(--grey20);
	background: var(--grey20);
	border-radius: 0.52vw;
	height: 3.8vw;
	min-width: 3.54vw;
	font-size: 1.4vw;
	padding-left: 0.5vw;
	padding-right: 0.5vw;

	&.active {
		background-color: var(--dn-core-blue);
		border-color: var(--dn-core-blue);
	}

	.dot {
		display: inline-block;
		border-radius: 0.75vw;
		height: 0.75vw;
		width: 0.75vw;
		background-color: var(--white);

		&.invert {
			filter: invert(1) brightness(0);
		}
	}

	.title {
		margin-left: 0.5vw;
		&.focus {
			color: var(--black);
		}
	}
}
