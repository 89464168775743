.more_info_icon {
	width: 1.4vw;
}

.resume_icon {
	width: 1.25vw;
}

.watchlistWrapper {
	margin-left: 2.5vw;
	position: relative;
}

.description_wrapper {
	padding-left: 3vw;
	margin-top: 6vw;
	position: relative;
}

.title {
	font-weight: 600;
	font-size: 2.5vw;
	padding-bottom: 1.5vw;
}

.description {
	font-weight: 400 !important;
	font-size: 1.2vw;
}

.gridBgImg {
	position: fixed;
	width: 100vw;
}

.gradientOverlay {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(to top right, rgba(18, 18, 18, 1) 55%, rgba(18, 18, 18, 0) 100%);
}

.menuWrapper {
	overflow: hidden;
	margin: 3vw 0 0 2.8vw;
	width: 96vw;
}
.grid_chip {
	margin-left: 0vw;
}
