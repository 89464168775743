.chip {
	background-color: var(--grey8-50);
	border-radius: 0.5vw;
	display: inline-block;
	font-size: var(--font-md-2);
	margin: 1vw;
	padding: 0.21vw 1.5vw;

	&.active {
		background-color: var(--dn-aqua);
		color: var(--black);
	}

	&.trayHighlight {
		background-color: #424141;
	}

	.chip_text {
		display: block;
		max-width: 17vw;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&.active {
			max-width: none;
		}
	}
}
