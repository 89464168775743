.cardbillboard_container {
	margin: 0vw 0.73vw;

	.cardbillboard {
		--horizontal-gutter: 1.66vw;
		--vertical-gutter: 1.66vw;
		position: relative;
		width: 86.87vw;
		height: 37.18vw;
		border-radius: 1.04vw;
		background-color: rgba(55, 55, 55, 0.5);
		overflow: hidden;

		&.active {
			border: 0.2083vw solid var(--white-tv);
		}
	}
}
