.container {
	display: inline-block;
	text-align: center;
	margin: 0 1.67vw;
	width: 9.9vw;
}

.avatar__title {
	color: #808080;
	font-size: 1.67vw;
	font-weight: normal;
	margin-bottom: 1.67vw;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	width: 9.9vw;
	white-space: nowrap;
}

.active {
	color: #f1f1f1;
}

.edit_button {
	width: 3.65vw !important;
	display: block;
	position: relative;
	margin: 2.6vw auto 0 auto;
	text-align: center;
	transition: transform 0.1s ease-in-out;

	img {
		opacity: 0.7;
		height: 3.6vw;
		width: 3.6vw;
	}

	&.focus {
		transform: scale3d(1.1, 1.1, 1);

		img {
			opacity: 1 !important;
		}
	}
}
