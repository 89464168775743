.dstv_logo {
	text-align: right;
	width: 5.73vw;
	height: auto;
	position: fixed;
	top: 3.13vw;
	right: 3.13vw;
}

.input {
	background-color: transparent;
	border: 1px solid #919191;
	color: #919191;
	font-size: 1.56vw;
	height: 4.17vw;
	line-height: 4.17vw;
	outline: none;
	overflow: hidden;
	padding: 0 1.06vw;
	text-align: left;
	width: 94%;
	margin-bottom: 2vw;
}

.profile__name__component {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100vw;
	height: 100vh;

	.info__container {
		display: block;
		float: left;
		z-index: 2;
		position: relative;
		overflow: hidden;
		margin: auto;

		.avatar {
			object-fit: contain;
			opacity: 0.8;
			display: block;
			filter: brightness(100%);
			width: 9.9vw;
			height: 9.9vw;
			margin: 5.2vw auto 4.69vw;
			//border:1px solid red;
		}
	}

	.grid__container {
		position: relative;
		margin: auto;

		.title {
			color: #f1f1f1;
			text-align: center;
			font: bold 2.08vw/1.5 var(--font-family);
			margin-bottom: 2.08vw;
		}
	}
}
