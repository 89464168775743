.steps_wrapper {
	align-items: center;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	height: 100%;
	width: 100%;
	justify-items: center;
	.titles_wrapper {
		& > h2:last-child,
		> p:last-child {
			margin-bottom: 3vw;
			width: 44vw;
			overflow-wrap: break-word;
		}

		h2 {
			font-size: 3.13vw;
			margin-bottom: 1vw;
		}

		p {
			font-size: 1.53vw;

			&.subtitle {
				margin-bottom: 1.8vw;
			}
		}
	}

	.content_wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		text-align: center;

		h3 {
			font-size: 1.88vw;
			margin-bottom: 0.63vw;
		}

		p {
			font-size: 1.46vw;
			margin-bottom: 2.5vw;
		}

		.qr_code {
			background: white;
			display: inline-block;
			padding: 1vh;
		}
	}

	& > div {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 77%;

		&:first-child {
			align-items: flex-start;
		}
	}

	.forgot_pwd_wrapper {
		margin-top: 1vw;
		width: 27.08vw;

		> button {
			width: 100%;
		}
	}

	.signup_button {
		margin-top: 1vw;
	}
}
