.modal_container {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 20;
	background-image: linear-gradient(90deg, rgba(15, 15, 15, 0.9) 0%, rgba(0, 0, 0, 1) 100%);
	.modal_poster {
		margin-top: 1.04vw;
		.modal_poster_image {
			width: 22.29vw;
			height: 12.86vw;
			border-radius: 1.04vw;
		}
	}
	.remaining_time_container {
		display: flex;
		justify-content: left;
	}
	.remaining_time {
		flex-shrink: 0;
		font-family: var(--font-family);
		color: #a9a9a9;
		font-size: 0.94vw;
		font-style: normal;
		font-weight: 500;
		line-height: 1.04vw;
		position: absolute;
		top: 32%;
		padding-left: 12px;
	}
	.progressbar_container {
		display: flex;
		justify-content: center;
	}
	.modal_title {
		margin-top: 0.52vw;
		width: 19.06vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #d9d9d9;
		font-family: var(--font-family);
	}
	.progressbar_wrapper {
		z-index: 1;
		width: 20.52vw;
		height: 0.21vw;
		flex-shrink: 0;
		position: absolute;
		top: 34.5%;
	}
	.modal {
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 1.04vw 0px 0px 1.04vw;
		width: 30vw;
		height: 50vh;
		&.continue-watching {
			margin-top: -1.04vw;
		}
		&.not-watching {
			margin-top: -8vw;
		}
	}
}
