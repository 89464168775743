.page_home {
	.home_editorials_wrapper {
		overflow: hidden;
		position: relative;
		width: 100vw;
		min-height: 100vh;

		.home_editorials {
			transition: transform 0.2s ease-in-out;
			will-change: transform;

			.home_editorial_row {
				position: absolute;
			}
		}
	}
}

.success {
	right: 0vw;
	width: fit-content;
}

.error {
	left: 59vw;
	border: 0.1vw solid #c22424;
	width: fit-content;
}

.home_coming_up_toast {
	left: 80vw !important;
	min-width: 10vw !important;
	max-width: 22vw !important;
	p {
		font-size: 1.042vw !important;
	}
	&.midrail_coming_up_toast {
		top: 2.2vw !important;
	}
}

.content_coming_up_toast {
	justify-content: flex-end !important;
	min-width: 10vw !important;
	max-width: 40vw !important;
	p {
		font-size: 1.042vw !important;
	}
}

.more_info_icon {
	width: 1.4vw;
}

.resume_icon {
	width: 1.25vw;
}
