.countdown {
	align-items: center;
	background: var(--white);
	border-radius: 50%;
	color: var(--black);
	display: flex;
	font-size: var(--font-md-4);
	font-weight: 700;
	height: 3.281vw;
	justify-content: center;
	width: 3.281vw;
}
