.autoplay_popup {
	background-color: var(--grey18);
	border-radius: 1.042vw;
	bottom: 4.1vw;
	max-width: 37vw;
	height: 19.635vw;
	padding: 1.9vw;
	position: absolute;
	right: 9.4vw;

	.autoplay_header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			font-weight: bold;
			font-weight: 700;
			color: var(--grey7);
			font-size: 1.302vw;
			line-height: 1.953vw;
		}
	}

	.autoplay_body {
		margin: 0.7vw 0 1.85vw;
		width: 95%;

		h2 {
			font-size: var(--font-lg-4);
			font-weight: 600;
			line-height: 3.125vw;
			color: var(--white);
			white-space: nowrap;
			max-width: 32vw;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		h3 {
			font-size: var(--font-md-4);
			font-weight: 500;
			margin-top: 0.25vw;
		}
	}

	.buttons_wrapper {
		display: flex;

		button {
			min-width: 8vw;
			&:last-child {
				margin-left: 1vw;
			}
		}
	}
}
