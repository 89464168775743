.carousel_wrapper {
	margin-bottom: 2.717vw;

	.title {
		margin-left: var(--gutter);
		margin-bottom: 1.25vw;
		font-size: 1.46vw;
		color: var(--grey14);
		letter-spacing: -0.01vw;

		&.active {
			color: var(--white-tv);
		}

		&.big_card_title {
			margin-bottom: 6vh;
		}
	}

	.carousel {
		display: inline-flex;
		margin: 0 var(--gutter);
		position: relative;
		white-space: nowrap;
		z-index: 1;
		transition: transform 0.2s ease-in-out;

		&.numberedCard {
			margin: 0 4.567vw;
		}

		&.contained_card_container {
			margin-left: 2.5vw;
		}

		> div {
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&.large {
			margin-bottom: 6vw;
		}
	}
}
