.cwmodal_item {
	display: flex;
	width: 28.9vw;
	padding: 0.983vw;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.52vw;
	font-size: 1.36vw;
	font-style: normal;
	letter-spacing: 0.03vw;
	margin-bottom: 1vw;
	border-radius: 0.625vw;
	background-color: #222230;
	color: #d9d9d9;
	font-family: var(--font-family);
	.icon {
		width: 1.46vw;
		height: 1.46vw;
		margin-bottom: 0.11vw;
	}
	&.focus {
		background-color: #03ffeb;
		color: black;
	}
}
