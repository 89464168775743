.grid_wrapper {
	display: block;
	overflow-y: scroll;
	padding-left: 1.727vw;
	padding-right: 3.125vw;
	padding-top: 1.7vw;
	position: relative;
	width: 100vw;
	.grid {
		align-items: center;
		display: block;
		flex-wrap: wrap;
		transition: transform 0.2s ease-in-out;
	}
}

.floatingGuide_wrapper {
	position: fixed;
	top: 46vw;
	left: 0vw;
}
