@import '../../../assets/styles/mixins';

.page-wrapper {
	display: flex;
	flex-direction: row;
	position: relative;
	width: 100vw;

	& > :nth-child(3) {
		position: relative;
		transition-duration: 0.3s;
		transition-property: transform;
		@include smooth-transition;

		&:before {
			content: '';
			position: absolute;
			left: -0.25vw;
			top: 0;
			width: 3.583vw;
			height: 100vh;
			background-image: linear-gradient(90deg, black 0%, transparent 100%);
			z-index: 15;
		}
	}

	&.hidden > :nth-child(3) {
		transform: translateX(-10.73vw);
		min-width: 100vw;
	}
}

.footer {
	bottom: 0vw;
	padding: 2.6vw 2.6vw;
	position: fixed;
	right: 0;
	z-index: 10;

	img {
		width: 6vw;
		height: auto;
	}
}
