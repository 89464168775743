.container {
	color: #fff;
}

.watchList_wrapper {
	height: 48vh;
	margin-top: 52vh;
	margin-left: 4vw;
	overflow: hidden;
	position: relative;

	.watchList {
		transition: transform 0.2s ease-in-out;
	}
}

.no_content_container {
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	z-index: 2;
	-ms-transform: translatey(0%);
	transform: translatey(0%);
	/* width: calc(100% - 13.96vw); */
	width: calc(100%);
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.no_content_container_top {
	transform: translateY(-30%);
}

.no_content_image {
	display: block;
	width: 3.52vw;
	height: 3.52vw;
}

.no_content_title {
	font-family: var(--font-family);
	color: #767676;
	font-size: 1.17vw;
	margin-top: 2.34vw;
	line-height: 1.76vw;
	font-weight: 500;
}
.no_content_text {
	font-family: var(--font-family);
	color: #767676;
	font-size: 1.17vw;
	margin-top: 0.52vw;
	line-height: 1.76vw;
	font-weight: 500;
}
.buttons_wrapper {
	margin-top: 1.67vw;
	height: 3.23vw;
	width: 6.09vw;
	min-width: 3.54vw;
	display: flex;
	align-items: center;
	border-radius: 0.52vw;
	font-family: var(--font-family);
	font-size: 1.46vw;
	font-weight: 500;
	line-height: 2.19vw;
	width: fit-content;
	background-color: var(--dn-core-blue);
	border: 0.1vw solid var(--dn-core-blue);
	color: black;
	padding: 0vw 1.5vw;
}
