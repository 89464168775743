.channel_item {
	align-items: center;
	background-color: var(--grey17);
	border-radius: 1vw;
	display: flex;
	width: 8.44vw;
	height: 6.09vw;
	justify-content: center;
	margin-bottom: 0.42vw;

	&.active {
		background-color: var(--grey12);
	}
	h5 {
		color: var(--white-tv);
		font-family: var(--font-family);
		font-size: 1.15vw;
		font-weight: 400;
	}

	img {
		max-width: 4.38vw;
		max-height: 3.33vw;
		padding-right: 1.41vw;
	}
}
