.toast {
	position: fixed;
	z-index: 15;
	align-items: center;
	justify-content: center;
	display: flex;
	top: 2.81vw;
	right: -8.33vw;
	width: auto;
	height: 4.95vw;
	line-height: 1.04vw;
	opacity: 0;
	padding: 0 1.56vw;
	border-radius: 1.04vw;
	background: var(--grey12);
	box-shadow: 0.21vw 0.21vw 0.21vw 0px rgba(0, 0, 0, 0.3);
	border: 0.1vw solid var(--cyan);
	transition: opacity 750ms;

	&.show {
		animation: slideLeft 750ms 1 normal cubic-bezier(0.22, 1, 0.36, 1) forwards;
		opacity: 1;
	}

	&.hide {
		animation: slideRight 1s 1 normal cubic-bezier(0.25, 1, 0.5, 1) forwards;
		opacity: 0;
	}

	p {
		font-family: var(--font-family);
		font-weight: 400;
		font-size: 1.56vw;
		line-height: 1.667vw;
		padding: 0 0.1vw 0 1.5vw;
		color: var(--white75);
	}
	.icon {
		width: 3vw;
	}
}

@keyframes slideLeft {
	0% {
		transform: translate3d(0, 0, 0);
	}

	100% {
		transform: translate3d(-11.25vw, 0, 0);
	}
}

@keyframes slideRight {
	0% {
		transform: translate3d(-11.25vw, 0, 0);
	}

	100% {
		transform: translate3d(0, 0, 0);
	}
}
