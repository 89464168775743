.expandable_card_container {
	border-radius: 1.04vw;
	display: inline-block;
	margin-right: 1.46vw;
	position: relative;
	transition: transform 0.2s ease-in-out;

	&.active {
		width: 26.25vw;
		height: 14.792vw;
		transform-origin: 0% 50%;
		transform: scale3d(1, 1, 1);
		border-radius: 1.04vw;
		background-color: #2c2a2a;
		border-color: var(--white-tv);
		opacity: 1;
		margin-right: 1vw;
		transition: transform 0.2s ease-in-out;

		&::before {
			content: '';
			position: absolute;
			top: -1vw;
			left: -1vw;
			right: -1vw;
			bottom: -1vw;
			border-radius: 2vw;
			border: 1vw solid #000;
			pointer-events: none;
			box-sizing: border-box;
			z-index: 5;
		}

		.expandablecard {
			opacity: 1;
		}

		.card_playButton {
			opacity: 1;
		}

		.card_image {
			border: none;
		}
	}

	.expandable_title {
		position: absolute;
		left: 0;
		right: 0;
		bottom: -3vh;
		color: var(--grey24);
		text-align: center;
		font-family: var(--font-family);
		font-size: 1.2vw;
		font-style: normal;
		font-weight: 500;
		z-index: 5;
	}
	.expandable_title_fallback {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10%;
		color: var(--grey22);
		text-align: center;
		font-family: var(--font-family);
		font-size: 1.2vw;
		font-style: normal;
		font-weight: 500;
		z-index: 6;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 2%;
	}
	.hide_title {
		display: none;
	}

	.card {
		opacity: 0.8;
		height: 14.792vw;
		transition: filter 0.2s ease-in-out;
		width: 11.094vw;

		.card_image {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	.expandablecard {
		opacity: 0.8;
		height: 14.792vw;
		transition: filter 0.2s ease-in-out;
		width: 26.25vw;
		overflow: hidden;
		background-color: #000;
		z-index: 4;

		.card_image {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	.card_image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 1.04vw;
		z-index: 3;
		background-size: 100% 100%;
		background-color: var(--grey25);
	}
}
