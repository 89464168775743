.recommendations_wrapper {
	margin-top: 42vh;
	width: 100vw;
	height: 58vh;
	overflow: hidden;
	position: relative;

	&:before {
		background-image: linear-gradient(to top, black 40%, transparent);
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.recommendations {
		transition-duration: 0.2s;
		transition-property: transform;
	}
}
