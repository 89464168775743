.metadata_movies {
	display: 'flex';
	flex-direction: 'row';

	&.midrail_metadata {
		color: #eeeeee;
	}
}

.metadata_movies p,
.metadata_movies ul {
	display: inline-block;
}

.billboard {
	position: relative;
	padding-left: var(--horizontal-gutter);
	padding-top: var(--vertical-gutter);
	width: 100vw;
	height: 46vh;

	&.contained_billboard {
		padding-left: 2.9vw;
		padding-top: 0vw;
	}

	&.full_height {
		height: 100%;
	}

	&.bottom {
		display: flex;
		height: 73.207vh;
		align-items: end;
	}

	.container-fluid {
		margin: 0vw;
		position: relative;

		&.centered {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.billboard_details {
		&.hide {
			visibility: hidden;
		}
	}

	.billboard__inner {
		font-family: var(--font-family);
		max-width: 43.49vw;
	}

	.header {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		font-family: var(--font-family);
		font-weight: 600;
		font-size: 2.813vw;
		line-height: 3.385vw;

		margin-bottom: 1.25vw;

		&.small {
			font-size: 1.458vw;
			line-height: 1.75vw;
		}
	}

	.showLogo {
		width: auto;
		max-width: 25vw;
		max-height: 4.16vw;
	}

	.billboard__progress {
		width: 100%;
		margin-top: 0.78vw;
	}

	.synopsisShort {
		margin-bottom: 0.83vw;
		font-family: var(--font-family);
		font-size: 1.146vw;
		line-height: 1.833vw;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		line-clamp: 3;
		-webkit-box-orient: vertical;
		white-space: normal;
	}

	.synopsisFull {
		display: -webkit-box;
		-moz-osx-font-smoothing: grayscale;
		-webkit-box-orient: vertical;
		-webkit-font-smoothing: antialiased;
		-webkit-line-clamp: 4;
		line-clamp: 4;

		color: var(--white90);
		font-family: var(--font-family);
		font-size: 1.146vw;
		line-height: 1.833vw;
		margin-bottom: 0.83vw;
		max-width: 43.49vw;
		overflow: hidden;
		position: relative;
		text-overflow: ellipsis;
		text-shadow: 0 0.1vw 0.2vw #000000;
		width: 100%;
	}

	.rating {
		background-color: #2a2a2a;
		border-radius: 0.52vw;
		width: max-content;
		height: 2.45vw;
		color: var(--white-tv);
		font-family: var(--font-family);
		font-size: 1.14vw;
		font-weight: 600;
		padding: 0.36vw 0.52vw;
	}

	.channelWrap {
		margin-bottom: 1.93vw;
		display: flex;
		align-items: center;
		min-height: 3.28vw;
		margin-bottom: 0.83vw;

		img {
			max-height: 3.28vw;
			max-width: 4.38vw;
			width: auto;
			margin-right: 0.57vw;
			order: 0;
		}

		p {
			font-size: 1.15vw;
			font-family: var(--font-family);
			color: #808080;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			&.midrail_wrap {
				font-size: 1.6vw;
				color: #eeeeee;
				margin-left: 0.5vw;
			}

			&.channelNumber {
				overflow: hidden;
				text-overflow: ellipsis;
				color: var(--white90);
				white-space: nowrap;
				font-size: 1.45vw;
				font-style: normal;
			}
		}
	}

	.channelLogo {
		max-width: 3.5vw;
		width: auto;
		margin-right: 0.46vw;
		margin: 0 0.57vw 0 0;
		text-align: center;

		img {
			width: 100%;
			height: auto;
		}
	}

	.hero_image_wrapper_lrg {
		position: absolute;
		right: 0;
		top: 0;
		width: 69.27vw;
	}

	.hero_image_wrapper {
		height: 56.25vw;
		position: absolute;
		right: 0;
		top: 0;
		width: 100vw;
		background-size: 100% auto;
		transition: background-image 0.2s ease-in-out;

		.hero_image {
			border-left: 0.05vw solid var(--black);
			width: 100%;
			height: auto;
		}

		&:before {
			background: radial-gradient(
				100% 100% at 100% 0%,
				transparent 0%,
				rgba(12, 12, 12, 0.5) 65.27%,
				rgba(12, 12, 12, 0.9) 81.425%,
				black 94.043%
			);
			content: '';
			display: inline-block;
			left: -0.05vw;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			height: 60vw;
		}
	}

	.showPage_progress_wrapper {
		position: relative;

		.showPage_progress {
			margin: 0.2vw 0vw 1.25vw 0vw;
			height: 0.42vw !important;
			border-radius: 0.21vw;
			max-width: 20.521vw;
		}

		.showPage_time_left {
			font-size: 1.146vw;
			color: var(--grey23);
		}
	}

	.imageOpacity {
		opacity: 0.1;
	}

	.expiryMessage {
		width: 44.06vw;
		height: 1.3vw;
		margin: 1.25vw 0 0;
		font-family: var(--font-family);
		font-size: 1.15vw;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #808080;
	}

	.availableUntil {
		width: 44.06vw;
		height: 1.3vw;
		font-family: var(--font-family);
		font-size: 1.04vw;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #808080;
		padding-bottom: 3.33vw;
		margin-top: -2.5vw;
	}

	.color__warning {
		color: #ee0000;
	}

	.airedInfo {
		display: block;
		color: #808080;
		font-size: 1.15vw;
		margin-bottom: 0.89vw;
	}

	.ageRating {
		display: inline-block;
		padding: 0.312vw 0.52vw;
		text-align: center;
		border-radius: 0.416vw;
		background-color: var(--grey12);

		color: var(--white75);
		font-family: var(--font-family);
		font-weight: 600;
		font-size: 1.146vw;
		line-height: 1.833vw;

		margin-bottom: 1.25vw;
	}

	.availDate {
		margin-left: 1.04vw;
		border-left: 0.05vw solid #808080;
		position: relative;
		padding-left: 0.78vw;
		color: #808080;
		font-size: 1.15vw;
	}

	.metadata {
		color: #808080;
		padding: 0;
		margin: 0;
		margin-bottom: 0.89vw;
		margin-right: 0.52vw;

		&:after {
			content: '•';
			margin-left: 0.52vw;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}

		&.live_metadata {
			display: flex;
			font-size: 1.14vw;
			text-transform: none;

			.label_wrapper {
				color: var(--white90);
				display: flex;
				align-items: center;
				font-weight: 500;
				line-height: 1.5;
				margin-left: 0.83vw;
			}
		}
	}

	.aqua_text_color,
	.aqua_text_color .metadata {
		color: var(--dn-aqua);
	}

	.player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 56.25vw;
		z-index: 0;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 56.25vw;
			flex-shrink: 0;
			background: linear-gradient(to left, transparent 43.87%, var(--black) 110.85%);
			z-index: 1;
		}
	}

	&.card {
		.header {
			font-size: 2.478vw;
		}

		.fade_out_move {
			margin-right: -0.52vw;
			/* Move 100px to the right */
			transition: all 0.6s;
		}

		.fade_in_move {
			position: relative;
			margin-right: 0vw;
			/* Move 100px to the right */
			transition: all 0.3s;
		}

		.fade_out_move_info {
			opacity: 0;
			transition: opacity 0.5s ease-out, transform 0.5s ease-in-out;
			/* Transition for opacity and transform */
			transform: translate3d(0.52vw, 0, 0);
			/* Move 100px to the right */
		}

		.fade_in_move_info {
			opacity: 1;
			transition: opacity 0.3s ease-in, transform 0.3s ease-in-out;
			/* Transition for opacity and transform */
			transform: translate3d(0vw, 0, 0);
			/* Move 100px to the right */
			position: relative;
			z-index: 9;
		}

		.fade_out_move_info_with_delay {
			opacity: 0;
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
			/* Transition for opacity and transform with delay */
			transform: translate3d(0.52vw, 0, 0);
			/* Move 100px to the right */
		}

		.fade_out {
			opacity: 0;
			transition: opacity 0.2s ease-in-out;
			/* Transition for opacity and transform */
		}

		.fade_in {
			opacity: 1;
			transition: opacity 0.2s ease-in-out;
			/* Transition for opacity and transform */
		}

		.hero_image_wrapper {
			height: 46vw;

			&:before {
				background: linear-gradient(to left, transparent 43.87%, var(--black) 110.85%);
			}
		}

		.player {
			width: 89vw;
			height: 50vw;
			margin-top: -4.6875vw;
		}
	}
}

.progress_wrapper {
	margin: 0.2vw 0vw 2.5vw 0.36vw;
	height: 0.42vw !important;
	border-radius: 0.21vw;
	max-width: 46vw;
}

.bookmark_details {
	font-weight: bold;
	font-size: 1.56vw;
	line-height: 1.5;
	margin-bottom: 0.89vw;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.buttons_wrapper {
	height: 3.54vw;
	min-width: 3.54vw;

	display: flex;
	align-items: center;
	border-radius: 0.521vw;

	font-family: var(--font-family);
	font-size: var(--font-lg-1);
	font-weight: bold;

	width: fit-content;
	background-color: var(--dn-core-blue);
	border: 0.1vw solid var(--dn-core-blue);
	color: black;
	padding: 0vw 1.5vw;
}

.play_buttonsmall {
	margin-right: 0.5vw;
	height: 3.073vw;
	width: 1.823vw;
}

.visible {
	display: block;
}

.hidden {
	display: none;
}
