.section_nav_item {
	color: var(--white);
	display: inline-block;
	font-size: var(--font-md-2);
	font-weight: bold;
	padding: 0.6vw 1.2vw 0.6vw 1.2vw;
	position: relative;
	margin-left: 0.2vw;
	border-radius: 0.417vw;

	&::first-letter {
		text-transform: capitalize;
	}

	&:before {
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		width: calc(100% + 0.64vw);
	}

	&:last-child {
		&:before {
			width: calc(100% - 3vw);
		}
	}

	&.active,
	&.focus {
		color: var(--black);
		opacity: 1;
		background: var(--cyan);
		text-align: center;
		margin: auto;
		border-radius: 0.55vw;
		margin-left: 0.4vw;
	}
	&.seasonTray {
		background: rgba(12, 12, 12, 0.5);
		color: rgba(217, 217, 217, 1);
	}
	&.trayHighlight {
		background: rgba(66, 65, 65, 1) !important;
	}
	&.seasonTrayFocus {
		color: var(--black);
		opacity: 1;
		background: var(--cyan);
	}
}
