.card_container {
	display: inline-block;
	position: relative;
	width: 20.625vw;
	height: 15.417vw;
	margin: 0vw 0.73vw 0vw 0.73vw;

	.centered {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.centered_vertical {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.centered_both {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.active {
		border-color: var(--white-tv);
		transform: scale3d(1.08, 1.08, 1.18);

		.card_image {
			border: 0.2083vw solid var(--white-tv);
		}
		.card_overlay {
			background: none;

			.card_playButton {
				width: 4.167vw;
			}
		}
	}
	.card_image_container {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.contained_card_image_container {
		position: absolute;
		width: 100%;
		height: 11.61vw;
		z-index: 1;
	}
	.card_image {
		position: absolute;
		width: 100%;
		height: 11.61vw;
		object-fit: cover;
		border-radius: 1.04vw;
		z-index: -2;
		background-color: var(--grey25);
	}

	.card_overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 11.61vw;
		border-radius: 1.04vw;
		opacity: 1;
		transition: opacity 0.3s ease;
		z-index: -1;
		margin-bottom: 0.26vw;
		background: rgba(0, 0, 0, 0.2);

		.card_playButton {
			opacity: 1;
			color: var(--grey23);
			text-align: center;
			z-index: 1;
			margin-bottom: 1.04vw;
		}

		.progress_wrapper {
			z-index: 1;
			width: 18.85vw;
			height: 0.208vw;
			position: absolute;
			bottom: 0.72vw;
		}

		.time_left {
			font-size: 1.04vw;
			color: var(--grey23);
			position: absolute;
			bottom: 1.04vw;
			left: 0.89vw;
		}
	}

	&.bigcard_container {
		width: 37.1875vw;
		height: 20.936vw;
		transform-origin: 0% 50%;
		opacity: 0.8;

		&.active {
			transform: scale3d(1.193, 1.193, 1);
			border-radius: 1.04vw;
			margin-right: 7vw;
			opacity: 1;

			.card_image {
				border: none;
			}
		}

		.card_image_container {
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 4;

			&::after {
				content: '';
				position: absolute;
				top: -1vw;
				left: -1vw;
				right: -1vw;
				bottom: -1vw;
				border-radius: 2.5vw;
				border: 1vw solid #000;
				pointer-events: none;
				box-sizing: border-box;
				z-index: 6;
			}
		}

		.card_image {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 1.04vw;
			background-size: 100% 100%;
			z-index: 3;
			background-color: var(--grey25);
		}

		.card_overlay {
			width: 100%;
			height: 100%;
			border-radius: 1.04vw;
			border: 0.0521vw solid #000;
			box-shadow: 0vw 0.2083vw 0.2083vw 0vw rgba(0, 0, 0, 0.25);
			z-index: 5;
		}

		.card_bottom_overlay {
			width: 37.29vw;
			height: 13.92vh;
			border-radius: 0vw 0vw 0.625vw 0.625vw;
			background: linear-gradient(180deg, rgba(13, 13, 13, 0) 37.03%, #0d0d0d 94.4%);
			position: absolute;
			top: 13.12vw;
		}

		.trailer_title {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -8%;
			color: var(--grey24);
			text-align: center;
			font-family: var(--font-family);
			font-size: 1.2vw;
			font-style: normal;
			font-weight: 500;
		}
		.trailer_title_fallback {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 10%;
			color: var(--grey22);
			text-align: center;
			font-family: var(--font-family);
			font-size: 1.2vw;
			font-style: normal;
			font-weight: 500;
			z-index: 6;
		}
		.hide_title {
			display: none;
		}
	}

	.card_title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 19vw;
		font-size: 1.25vw;
		font-weight: 600;
		color: var(--grey24);
		margin-left: 0.9375vw;
		margin-top: 0.42vw;
		font-family: var(--font-family);
		z-index: 2;
		position: absolute;
		top: 75%;
	}

	.card_metadata {
		font-size: 0.94vw;
		color: var(--grey22);
		position: absolute;
		top: 92%;
		left: 0.9375vw;
		font-family: var(--font-family);
		z-index: 2;
		font-style: normal;
		font-weight: 500;
	}

	.time_info {
		font-weight: 500;
		color: var(--grey22);
		margin-left: 0.9375vw;
		margin-top: 0.42vw;
		top: 87%;
		position: absolute;
	}
}
.hidden {
	visibility: hidden;
}
