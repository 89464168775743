.keyboard_action_button_wrapper {
	align-items: center;
	display: flex;
	height: 3vw;
	justify-content: center;
	margin-right: 0.45vw;

	.keyboard_action_button {
		border: 0.11vw solid var(--grey19);
		color: var(--grey19);
		display: inline-block;
		font-size: var(--font-lg-1);
		height: 2.344vw;
		width: 4.863vw;
		letter-spacing: 0.04vw;
		position: relative;
		text-align: center;
		border-radius: 0.26vw;

		&.focus {
			background-color: var(--dn-core-blue);
			border-color: var(--dn-core-blue);
			color: var(--black);
		}
	}

	.keyboard_sprite_button {
		background-position: left center;
		background-repeat: repeat-x;
		background-size: 200%;
		display: block;
		height: 2.4vw;
		position: relative;
		width: 4.17vw;
		border-radius: 0.2vw;

		&.focus {
			background-position: right center;
			opacity: 1;
		}
	}
}

.ok_button {
	margin-top: auto;
}
