@import '../../../assets/styles/mixins';

.showpage_recommendations_wrapper {
	@include smooth-transition;
	height: 17vw;
	margin-top: 24.8vh;
	overflow: hidden;
	position: relative;
	transition-duration: 0.3s;
	transition-property: transform;
	width: 100vw;
	padding-bottom: 100vh;

	.showpage_recommendations {
		transition-duration: 0.2s;
		transition-property: transform;
	}

	&:before {
		background-color: #000;
		content: '';
		height: 100vh;
		left: 0;
		position: absolute;
		top: 0;
		width: 100vw;
	}

	&.active {
		height: 100vh;
		transform: translate3d(0, -89.8vh, 0);
	}
}
