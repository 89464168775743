.container {
	position: relative;
	width: 20.625vw;
	min-height: 20.65vh;
	display: inline-block;
	margin: 0vw 0.73vw 0vw 0.73vw;
	border-radius: 1.04vw;

	&.active {
		transform: scale3d(1.08, 1.08, 1.18);
		filter: brightness(1);
		border-color: var(--white-tv);

		.card {
			border: 0.2083vw solid var(--white-tv);

			.playButton {
				opacity: 1;
				width: 4.167vw;
			}

			.meta {
				color: var(--white-tv);
			}
		}
	}

	.card {
		background-color: var(--grey12);
		width: 100%;
		height: 100%;
		border-radius: 1.04vw;
		position: relative;
		overflow: hidden;

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 1.04vw;
		}

		.gradient_overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 1.04vw;
			background: linear-gradient(116deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 54.52%);
		}

		.channel_logonumber {
			position: absolute;
			top: 0.833vw;
			left: 0.833vw;
			display: flex;
			flex-direction: row;
			align-items: center;
			z-index: 2;
		}

		.channel_logo_icon {
			width: 2.18vw;
			height: 2.18vw;
			object-fit: cover;
			margin: 0 0.57vw 0 0;
		}

		.channel_no {
			line-height: 140%;
			font-size: 1.04vw;
			color: #eee;
			font-family: var(--font-family);
		}
		.event_label {
			background: linear-gradient(90deg, var(--dn-aqua) 0%, var(--dn-aqua) 38.65%, #3a8fda 122.78%);
			color: #002747;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 0vw 1.562vw 1.562vw 1.041vw;
			text-align: center;
			font-size: 1.041vw;
			font-weight: 600;
			bottom: 0;
			letter-spacing: 0.02vw;
			padding: 0.417vw 0.625vw 0.417vw 0.625vw;
			position: absolute;
			z-index: 2;

			&.expired_label {
				background: #1919ba;
				color: var(--white90);
			}
		}

		.play_buttonsmall {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}

		.group_icon {
			width: 3.12vw;
			height: 3.12vw;
		}

		.image {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0;
			background-color: var(--grey25);
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;

			.playButton {
				filter: drop-shadow(0vw 0.26vw 0.52vw rgba(0, 0, 0, 0.8));
				opacity: 0;
				position: absolute;
				text-align: center;
				transition: opacity 0.35s ease;
				width: 3.125vw;
			}
		}

		.logo {
			height: 2.083vw;
			width: auto;
		}

		.meta {
			color: var(--grey7);
			padding: 0.415vw 0.78vw;

			.time {
				font-size: 1.15vw;
				margin-bottom: 0.26vw;
			}

			.title {
				font-size: 1.15vw;
				font-weight: bold;
				margin-bottom: 0.52vw;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.progress_wrapper {
				height: 0.21vw;
			}
		}
	}

	&.card_meta {
		min-height: 15.417vw;

		.card {
			height: 11.61vw;
		}

		.card_info {
			margin-left: 0.9375vw;
			margin-top: 0.3vw;
			font-family: var(--font-family);

			.card_title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 19vw;
				font-size: 1.25vw;
				font-weight: 600;
				color: var(--grey24);
			}
			.time_info {
				font-weight: 500;
				color: var(--grey22);
				margin-top: 0.3vw;
			}
		}
	}
}
