.sidepanel_item {
	height: 5vw;
	font-size: 1.6vw;
	font-weight: bold;
	letter-spacing: 0.03vw;
	padding: 1.5vw 0vw 2vw 2vw;
	margin-bottom: 1vw;

	&.focus {
		background-color: #03ffeb;
		color: black;
	}
}
