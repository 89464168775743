.time_widget {
	background-color: var(--grey14-7);
	border-radius: 0.521vw;
	color: var(--white-tv);
	font-size: 1.56vw;
	font-weight: 900;
	padding: 0.47vw 0.55vw;

	&.player {
		font-weight: 500;
		line-height: 1.88vw;
	}
}
