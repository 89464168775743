@import '../../../assets/styles/mixins';

.section_menu_wrapper {
	position: absolute;
	transition-duration: 0.5s;
	transition-property: transform;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	z-index: 12;
	margin: 3vw 0 0 0;
	padding-left: 2.8vw;
	width: 90vw;
	border-radius: 0.55vw;
	@include smooth-transition;

	.shadow {
		position: absolute;
		top: 0vw;
		right: 0vw;
		z-index: 30;
		width: 7vw;
		height: 100%;
		border-radius: 0.55vw;
		border-right: 0.5vw solid var(--grey2);
		background: linear-gradient(270deg, var(--grey2) 0%, rgba(35, 35, 35, 0) 109.77%);
	}

	.section_menu {
		@include smooth-transition;
		position: relative;
		width: fit-content;
		transition-duration: 0.2s;
		transition-property: transform;
		color: var(--white);
		background-color: var(--grey2);
		border-radius: 0.55vw;
	}

	&.hidden {
		transform: translate3d(0, -200%, 0);
	}
}
