.card_container {
	align-items: flex-end;
	border-radius: 1.04vw;
	display: inline-flex;
	margin-right: 1.46vw;
	position: relative;
	transition: transform 0.2s ease-in-out;

	&.numberedCard {
		margin-right: 2.26vw;

		&.active {
			.number {
				margin-right: -0.85vw;
				margin-bottom: -1.5vh;
			}
		}
	}

	&.active {
		border-color: var(--white-tv);
		transform: scale3d(1.15, 1.15, 1);

		.card_playButton {
			opacity: 1;
		}

		.card {
			filter: brightness(1);
		}
		.card_image {
			border: 0.2083vw solid var(--white-tv);
		}
	}

	.card {
		filter: brightness(0.8);
		height: 14.792vw;
		transition: filter 0.2s ease-in-out;
		width: 11.094vw;
		position: relative;

		.card_image {
			width: 100%;
			height: 100%;
			border-radius: 1.04vw;
			background-size: 100% 100%;
			background-color: var(--grey25);
		}
	}

	.number {
		background: linear-gradient(var(--dn-aqua), var(--dn-secondary-blue));
		background-clip: text;
		-webkit-background-clip: text;
		color: var(--black);
		font-size: 6vw;
		font-weight: bold;
		letter-spacing: -0.5vw;
		font-family: var(--font-family);
		-webkit-text-fill-color: var(--black);
		-webkit-text-stroke: 0.16vw;
		-webkit-text-stroke-color: transparent;
		outline: 2px solid transparent;
		outline-offset: -2px;
		line-height: 1;
		margin-right: -0.3vw;
		z-index: -1;
		margin-bottom: -1.3vh;
	}

	&.expandable_card_container {
		&.active {
			width: 26.25vw;
			height: 14.792vw;
			transform-origin: 0% 50%;
			transform: scale3d(1, 1, 1);
			border-radius: 1.04vw;
			background-color: #2c2a2a;
			border-color: var(--white-tv);
			filter: brightness(1);
			margin-right: 1vw;
			transition: transform 0.2s ease-in-out;

			.card_playButton {
				opacity: 1;
			}
			.card_image {
				border: none;
			}
		}

		.expandable_title {
			position: absolute;
			left: 0;
			right: 0;
			bottom: -3vh;
			color: var(--grey24);
			text-align: center;
			font-family: var(--font-family);
			font-size: 1.2vw;
			font-style: normal;
			font-weight: 500;
		}
	}

	.expandablecard {
		filter: brightness(0.8);
		height: 14.792vw;
		transition: filter 0.2s ease-in-out;
		width: 26.25vw;
		overflow: hidden;
		background-color: #000;

		.card_image {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 1.04vw;
			background-size: 100% 100%;
			z-index: -2;
			background-color: var(--grey25);
		}
	}

	.card_image {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 1.04vw;
		z-index: -2;
		background-size: 100% 100%;
		background-color: var(--grey25);
	}
}
