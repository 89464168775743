.episode_list_item {
	display: inline-block;
	position: relative;
	width: 20.625vw;
	height: 15.417vw;
	margin-right: 1.46vw;
	border-radius: 1.04vw;

	.centered {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	.centered_vertical {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.centered_both {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.focus {
		opacity: 1;
		transform: scale3d(1.08, 1.08, 1.18);

		.episode_image {
			border: 0.2083vw solid var(--white-tv);
		}

		.episode_image_wrapper {
			.card_playButton {
				width: 4.167vw;
				height: 4.167vw;
			}
		}
	}

	.episode_image_wrapper {
		position: absolute;
		width: 20.625vw;
		height: 11.615vw;

		.episode_image {
			position: absolute;
			height: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 1.04vw;
			background-color: var(--grey25);
		}

		.card_playButton {
			width: 3.125vw;
			height: 3.125vw;
		}

		.progress_wrapper {
			z-index: 1;
			width: 18.85vw;
			height: 0.208vw;
			position: absolute;
			bottom: 0.73vw;
		}
		.time_left {
			font-size: 1.04vw;
			color: var(--grey23);
			position: absolute;
			bottom: 1.04vw;
			left: 0.89vw;
			z-index: 1;
		}
	}

	.card_title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 19vw;
		font-size: 1.25vw;
		font-weight: 600;
		color: var(--grey24);
		margin-left: 0.9375vw;
		margin-top: 0.42vw;
		font-family: var(--font-family);
		z-index: 2;
		position: absolute;
		bottom: 13%;
	}

	.card_metadata {
		font-size: 0.94vw;
		color: var(--grey22);
		position: absolute;
		left: 0.9375vw;
		font-family: var(--font-family);
		z-index: 2;
		font-style: normal;
		font-weight: 500;
		bottom: 4%;
	}
}
