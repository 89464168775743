.midrail_billboard_container {
	margin-left: var(--gutter);
	margin-bottom: 2.717vw;
	.title {
		margin-bottom: 1.25vw;
		font-size: 1.46vw;
		color: var(--grey14);
		letter-spacing: -0.01vw;

		&.active {
			color: var(--white-tv);
		}
	}

	.midrail_billboard {
		--horizontal-gutter: 1.66vw;
		--vertical-gutter: 1.66vw;
		position: relative;
		width: 88.3vw;
		height: 40.625vw;
		background-color: rgba(55, 55, 55, 0.5);
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			top: -1vw;
			left: -1vw;
			right: -1vw;
			bottom: -1vw;
			border-radius: 3vw;
			border: 1vw solid #000;
			pointer-events: none;
			box-sizing: border-box;
		}
	}
}
