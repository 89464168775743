.page_search {
	.text_item {
		display: flex;
		margin: 1vw 0 0.5vw 3vw;
		font-size: var(--font-lg);

		.no_search_text {
			white-space: nowrap;
			max-width: 80vw;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.suggestion_container {
		overflow: hidden;

		.selected {
			background-color: var(--grey4);
			color: var(--black);
		}
	}

	.search_coming_up_toast {
		right: -3.125vw;

		p {
			font-size: var(--font-md-2);
		}
	}

	.search_container {
		margin-left: auto;
		top: 7vw;
		left: 2vw;
		display: flex;
		height: 47.4vw;
		position: absolute;
		z-index: 3;

		.keyboard_grid {
			display: block;
			position: relative;
			top: 8vw;
		}

		.grid_wrapper {
			display: block;
			overflow: hidden;
			padding-top: 1.5vw;
			position: relative;
			width: 88vw;
			color: var(--white);

			&.grid_with_search {
				top: 6.5vw;
			}

			&.content_with_no_result {
				top: 3vw;
			}
		}
	}
}
