.textBlock {
	margin-left: 0vw;
}

.mainItem {
	height: 5vw;
	width: 100%;
	font-size: 1.46vw;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	padding: 0.8vw 0vw 1vw 1vw;
	margin-bottom: 1vw;
}

.caption {
	font-size: 1.042vw;
	margin-left: 1.1vw;
	font-weight: 700;
	font-style: normal;
}
.focus {
	background-color: var(--dn-core-blue);
	color: var(--black);
}
.white {
	color: var(--white);
}
.value {
	margin: 0 0 0.278vw 0vw;
}
