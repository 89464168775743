.trailer_player {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;

	// TODO: check
	// border-radius: 1.04vw;
	// position: absolute;
	// z-index: 2;
}
