@import '../../../assets/styles/mixins';

.catchup_container {
	transition-duration: 0.5s;
	transition-property: transform;
	@include smooth-transition;
	margin-top: -12.23vw;

	&.active {
		transform: translate3d(0, 19.23vw, 0);
	}
}
